<script setup lang="ts">
// Components
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();

// Data
const state = reactive({
  selectedTab: 0,
});

const tabs: { label: string; to: string }[] = [
  {
    label: t('global.alert_configuration'),
    to: '/configuration/alerts',
  },
  {
    label: t('global.production times'),
    to: '/configuration/production-hours',
  },
  {
    label: t('global.meters'),
    to: '/configuration/machines',
  },
  {
    label: t('global.contracts'),
    to: '/configuration/contracts',
  },
];
</script>

<template>
  <div class="flex flex-col w-full h-full overflow-y-scroll bg-gray-50">
    <ui-header :title="t('global.configurations')" />
    <div class="flex flex-1 flex-col gap-4 p-4">
      <ui-nav-tabs v-model="state.selectedTab" :items="tabs" />
      <nuxt-page />
    </div>
  </div>
</template>
